import { Button } from "@material-ui/core";
import React, { useRef } from "react";
import { StayTypeFilterOptions, StayTypeFilterValue } from "../../common";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { StayTypeFilterPopover } from "../../../StayTypeFilterPopover";

export interface StayTypeButtonGroupProps {
  selectedStayType: StayTypeFilterValue;
  onChange: (stayType: StayTypeFilterValue) => void;
}

export const StayTypeButtonGroup = ({
  selectedStayType,
  onChange,
}: StayTypeButtonGroupProps) => {
  const { matchesMobile } = useDeviceTypes();

  const containerRef = useRef<HTMLDivElement>(null);

  const handleOnClick = (value: StayTypeFilterValue) => {
    onChange(value);
  };

  const buttonControls = StayTypeFilterOptions.map(({ value, label }) => (
    <Button
      className={clsx(styles["button"], {
        [styles["selected"]]: selectedStayType === value,
        [styles["mobile"]]: matchesMobile,
      })}
      key={value}
      value={value}
      role="radio"
      aria-checked={selectedStayType === value}
      aria-pressed={undefined}
      onClick={() => handleOnClick(value)}
    >
      {label}
    </Button>
  ));

  return (
    <>
      <div
        ref={containerRef}
        className={clsx(styles["button-group"], {
          [styles["mobile"]]: matchesMobile,
        })}
      >
        {buttonControls}
      </div>
      {!matchesMobile && <StayTypeFilterPopover anchorRef={containerRef} />}
    </>
  );
};
