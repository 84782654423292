import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getFirstIndexOfHomesInAvailabilityLodgingsResults,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
} from "../../reducer/selectors";
import { ManagedStayTypeFilterPopover } from "./component";

interface IStateProps {
  availabilityResultsHasHomesAndHotels: boolean;
  availabilityResultsFirstIndexOfHomeResult: number;
}

const mapStateToProps = (state: IStoreState) => {
  return {
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    availabilityResultsFirstIndexOfHomeResult:
      getFirstIndexOfHomesInAvailabilityLodgingsResults(state),
  };
};

interface IDispatchProps {}

const mapDispatchToProps = {};

interface IOwnProps {
  anchorRef?: React.RefObject<HTMLElement | null>;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type StayTypeFilterPopoverConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedStayTypeFilterPopover = connector(
  ManagedStayTypeFilterPopover
);
