import { mapAmenitiesToTopAmenities } from "halifax";
import { intersection, isEqual } from "lodash-es";
import {
  AmenityEnum,
  CorpLodging,
  HotelStarRatingEnum,
  Lodging,
  LodgingCollectionEnum,
  MealPlanKindEnum,
  TopAmenityEnum,
  VacationRentalAmenityKindEnum as VRAmenityKind,
} from "redmond";
import {
  HomeRoomsAndBeds as VRRoomsAndBeds,
  StayType,
} from "redmond/hotels-module/interfaces";
import { initialFilterState } from "..";

export const performAmenitiesFilter = (
  lodging: Lodging,
  amenitiesFilter: AmenityEnum[]
): boolean => {
  if (amenitiesFilter.length > 0 && lodging.stayType !== StayType.Homes) {
    const amenitiesList = (lodging.lodging.amenities ?? []).map(
      (status) => status.amenity
    );

    const amenitiesFilterMappedToTopAmenities = mapAmenitiesToTopAmenities(
      amenitiesFilter
    ).map((topAmenity) => topAmenity.label);

    const lodgingAmenitiesMappedToTopAmenities = mapAmenitiesToTopAmenities(
      amenitiesList
    ).map((topAmenity) => topAmenity.label);
    if (
      amenitiesFilterMappedToTopAmenities.some(
        (amenityFilter) =>
          amenityFilter === "Breakfast available" ||
          amenityFilter === "Breakfast included"
      ) &&
      (amenitiesList.some((amenity) => amenity === AmenityEnum.FreeBreakfast) || // Include paid breakfast AND free breakfast for `Breakfast available` filter
        lodging.lodgingCollection === LodgingCollectionEnum.Premier) // Include PC for `Breakfast included` and `Breakfast available` filter
    ) {
      return true;
    }
    if (
      !isEqual(
        intersection(
          amenitiesFilterMappedToTopAmenities,
          lodgingAmenitiesMappedToTopAmenities
        ),
        amenitiesFilterMappedToTopAmenities
      )
    )
      return false;
  }

  return true;
};

// Map additional amenities that are close to the ones we display in the filter
const vrAmenityToFilterAmenityMap: { [key in VRAmenityKind]?: VRAmenityKind } =
  {
    [VRAmenityKind.Kitchenette]: VRAmenityKind.Kitchen,
  };

export const vrAmenityToTopLodgingAmenityMap: {
  [key in VRAmenityKind]?: TopAmenityEnum;
} = {
  [VRAmenityKind.Pool]: TopAmenityEnum.Pool,
  [VRAmenityKind.Parking]: TopAmenityEnum.ParkingIncluded,
  [VRAmenityKind.Wifi]: TopAmenityEnum.FreeWifi,
  [VRAmenityKind.PetFriendly]: TopAmenityEnum.PetFriendly,
  [VRAmenityKind.DedicatedWorkspace]: TopAmenityEnum.BusinessServices,
  [VRAmenityKind.Dryer]: TopAmenityEnum.Laundry,
};

export const topLodgingAmenityToVrAmenityMap: {
  [key in TopAmenityEnum]?: VRAmenityKind;
} = Object.fromEntries(
  Object.entries(vrAmenityToTopLodgingAmenityMap).map(([key, value]) => [
    value,
    key,
  ])
);

export const lodgingAndVrAmenityCountsAreEqual = (
  amenities: AmenityEnum[],
  vrAmenities: VRAmenityKind[]
): boolean => {
  if (amenities.length === 0 && vrAmenities.length === 0) {
    return true;
  }
  const topAmenities = mapAmenitiesToTopAmenities(amenities);
  return topAmenities.length === vrAmenities.length;
};

export const performVrAmenitiesFilter = (
  lodging: Lodging,
  amenitiesFilter: VRAmenityKind[]
): boolean => {
  if (amenitiesFilter.length > 0 && lodging.stayType === StayType.Homes) {
    const vrAmenityKinds = (
      lodging.homesListingDetails?.listingContent?.amenities ?? []
    ).map((amenity) => amenity.kind);

    const resolvedVrAmenityKinds = vrAmenityKinds.map(
      (kind) => vrAmenityToFilterAmenityMap[kind] || kind
    );

    const lodgingAmenitiesList = (lodging.lodging.amenities ?? []).map(
      (status) => status.amenity
    );

    const vrAmenityKindsDerivedFromLodgingAmenities =
      mapAmenitiesToTopAmenities(lodgingAmenitiesList)
        .map((topAmenity) => topLodgingAmenityToVrAmenityMap[topAmenity.value])
        .filter(
          (vrAmenity): vrAmenity is VRAmenityKind => vrAmenity !== undefined
        );

    // Combine VR amenities from both sources to mainly handle the case where pet friendly
    // is not explicitly set in the VR amenities but is set in the lodging amenities.
    const combinedVrAmenities = [
      ...resolvedVrAmenityKinds,
      ...vrAmenityKindsDerivedFromLodgingAmenities,
    ];

    return isEqual(
      intersection(amenitiesFilter, combinedVrAmenities),
      amenitiesFilter
    );
  }
  return true;
};

export const performVrRoomsAndBedsFilter = (
  lodging: Lodging,
  roomsAndBedsFilter?: VRRoomsAndBeds
): boolean => {
  if (roomsAndBedsFilter && lodging.stayType === StayType.Homes) {
    const roomsAndBeds =
      lodging.homesListingDetails?.listingContent?.listingLayout;

    if (roomsAndBeds) {
      if (
        roomsAndBedsFilter.numberOfBedrooms &&
        roomsAndBedsFilter.numberOfBedrooms > 0
      ) {
        if (
          (roomsAndBeds.numberOfBedrooms || 0) <
          roomsAndBedsFilter.numberOfBedrooms
        ) {
          return false;
        }
      }
      if (
        roomsAndBedsFilter.numberOfBathrooms &&
        roomsAndBedsFilter.numberOfBathrooms > 0
      ) {
        if (
          (roomsAndBeds.numberOfBathrooms || 0) <
          roomsAndBedsFilter.numberOfBathrooms
        ) {
          return false;
        }
      }
      if (
        roomsAndBedsFilter.numberOfBeds &&
        roomsAndBedsFilter.numberOfBeds > 0
      ) {
        if (
          (roomsAndBeds.numberOfBeds || 0) < roomsAndBedsFilter.numberOfBeds
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

export const performStarRatingsFilter = (
  lodging: Lodging,
  starRatingsFilter: HotelStarRatingEnum[]
): boolean => {
  if (
    starRatingsFilter.length > 0 &&
    !starRatingsFilter.includes(lodging.lodging.starRating)
  ) {
    return false;
  }

  return true;
};

export const performMaxPriceFilter = (
  lodging: Lodging,
  maxPrice: number
): boolean => {
  if (
    maxPrice !== initialFilterState.maxPrice &&
    lodging.available &&
    (!lodging.price || lodging.price.nightlyPrice.fiat.value > maxPrice)
  ) {
    return false;
  }

  return true;
};

export const performHotelNameFilter = (
  lodging: Lodging,
  hotelName: string
): boolean => {
  if (!lodging.lodging.name.toLowerCase().includes(hotelName.toLowerCase())) {
    return false;
  }

  return true;
};

export const performPremierAndLifestyleCollectionFilter = (
  lodging: Lodging,
  showPremiumStaysOnlyFilter: boolean
) => {
  if (showPremiumStaysOnlyFilter) {
    return (
      lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
      lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    );
  }
  return true;
};

export const performLifestyleCollectionFilter = (
  lodging: Lodging,
  showLifestyleStaysOnlyFilter: boolean
) => {
  if (showLifestyleStaysOnlyFilter) {
    return lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle;
  }
  return true;
};

export const performFreeCancelFilter = (
  lodging: Lodging,
  freeCancel: boolean
) => (freeCancel ? lodging.isFreeCancel : true);

export const performHotelsOnSaleFilter = (
  lodging: Lodging,
  saleOnly: boolean
) => (saleOnly ? !!lodging.bestPromotionThisLodging : true);

export const performPolicyFilter = (
  lodging: Lodging | CorpLodging,
  isInPolicyEnabled: boolean
): boolean => {
  const corpLodging = (lodging as CorpLodging).lodging.corporateTravel;
  if (isInPolicyEnabled && corpLodging) {
    return corpLodging.policyCompliance.isInPolicy;
  }

  return true;
};

export const performLoyaltyProgramFilter = (
  lodging: Lodging,
  selectedLoyaltyPrograms: string[]
): boolean => {
  if (selectedLoyaltyPrograms.length === 0) {
    return true;
  }

  const isLoyaltyProgramSelected = selectedLoyaltyPrograms.some(
    (selectedLoyaltyProgram) => {
      return selectedLoyaltyProgram === lodging?.loyaltyProgramCode;
    }
  );

  return isLoyaltyProgramSelected;
};

export const performStayTypeFilter = (
  lodging: Lodging,
  selectedStayTypes: StayType[]
): boolean => {
  if (selectedStayTypes.length === 0) {
    return true;
  }

  return selectedStayTypes.includes(lodging.stayType || StayType.Hotels);
};

export const performMealPlanTypeFilter = (
  lodging: Lodging,
  selectedMealPlanTypes: MealPlanKindEnum[]
): boolean => {
  if (selectedMealPlanTypes.length === 0) {
    return true;
  }

  if (
    /* If lodging is premier collection, ignore this filter for Breakfast plan as breakfast is included in all PC */
    lodging.lodgingCollection === LodgingCollectionEnum.Premier &&
    selectedMealPlanTypes.includes(MealPlanKindEnum.Breakfast)
  ) {
    return true;
  }
  return selectedMealPlanTypes.includes(
    lodging.price?.mealPlan?.kind || MealPlanKindEnum.RoomOnly
  );
};
