import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import { userPriceDropAmountSelector } from "../ancillary/reducer";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";
import { getApplyUserHotelPreferences } from "../search/reducer";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import { actions } from "./actions";
import {
  fetchUserHotelPreferences,
  listPaymentMethods,
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHasDedicatedStayTypesFilter,
  setStarRatingsFilter,
} from "./actions/actions";
import { HotelAvailability } from "./component";
import {
  getHotelAvailabilityCallState,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityStayTypeFilter,
  getHotelAvailabilityUntilDate,
  getMapBound,
  getUserHotelPreferences,
  getViewHotelsNearLocation,
} from "./reducer/index";

const mapStateToProps = (state: IStoreState) => {
  const callState = getHotelAvailabilityCallState(state);

  return {
    location: getHotelAvailabilitySearchLocationResult(state),
    fromDate: getHotelAvailabilityFromDate(state),
    untilDate: getHotelAvailabilityUntilDate(state),
    hotelAvailabilityCallState: callState,
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    isSearchingMap: !!getMapBound(state) || !!getViewHotelsNearLocation(state),
    userPriceDropAmount: userPriceDropAmountSelector(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHotelPreferences: getUserHotelPreferences(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    stayTypes: getHotelAvailabilityStayTypeFilter(state),
  };
};

const mapDispatchToProps = {
  fetchInitialHotelAvailability: actions.fetchInitialHotelAvailability,
  fetchTravelWalletDetails,
  fetchTravelWalletCreditHistory,
  setStarRatingsFilter,
  setAmenitiesFilter,
  setFreeCancelFilter,
  fetchUserHotelPreferences,
  listPaymentMethods,
  setHasDedicatedStayTypesFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelAvailabilityConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelAvailability = withRouter(
  connector(HotelAvailability)
);
