import { connect, ConnectedProps } from "react-redux";
import {
  getHotelAvailabilityLodgingIdInFocus,
  getHotelAvailabilityCallState,
  getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching,
  getAppliedHotelAvailabilityFilterCount,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
  getHotelAvailabilityStayTypeFilter,
  getViewHotelsNearLocation,
  getMapBound,
  getFilteredAndSortedHotelAvailabilityLodgings,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { MobileHotelAvailability } from "./component";
import { withRouter } from "react-router-dom";
import { getApplyUserHotelPreferences } from "../../../search/reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setMaxPriceFilter,
  setStarRatingsFilter,
  setVacationRentalAmenitiesFilter,
  setVacationRentalRoomsAndBedsFilter,
} from "../../actions/actions";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";
import {
  initialFilterState,
  getHotelAvailabilitySearchLocationResult,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const lodgingIdInFocus = getHotelAvailabilityLodgingIdInFocus(state);
  const lodgings = getFilteredAndSortedHotelAvailabilityLodgings(state);
  const hotelAvailabilityCallState = getHotelAvailabilityCallState(state);
  const isFilteredHotelAvailabilityLodgingsEmpty =
    getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching(state);
  const searchedLocation = getHotelAvailabilitySearchLocationResult(state);

  return {
    lodgingIdInFocus,
    lodgings,
    hotelAvailabilityCallState,
    isFilteredHotelAvailabilityLodgingsEmpty,
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    appliedFilterCount: getAppliedHotelAvailabilityFilterCount(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    stayTypes: getHotelAvailabilityStayTypeFilter(state),
    searchedLocation,
    isSearchingMap: !!getViewHotelsNearLocation(state) || !!getMapBound(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setApplyUserHotelPreferences(false));
      dispatch(
        setVacationRentalAmenitiesFilter(initialFilterState.vrAmenities)
      );
      dispatch(
        setVacationRentalRoomsAndBedsFilter(initialFilterState.vrRoomsAndBeds)
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelAvailability = withRouter(
  connector(MobileHotelAvailability)
);
