import React from "react";
import { IconName, LandingBenefit } from "halifax";

export const TITLE = (
  <>
    Thousands of <strong>hotels</strong> to choose from.
  </>
);

export const STAYS_TITLE = (
  <>
    Thousands of <strong>stays</strong> to choose from.
  </>
);

export const SUBTITLE =
  "Book with flexibility and enjoy elevated travel benefits.";

export const STAYS_SUBTITLE =
  "Book hotels or professionally managed vacation rentals.";

export const EARN_ENHANCEMENT_TITLE = (
  hotelsEarnRate: string | number,
  homesEarnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  let earnEnhancementTitle = <></>;

  if (hotelsEarnRate && !homesEarnRate) {
    earnEnhancementTitle = (
      <span className="font-regular">
        Earn{" "}
        <strong>
          {hotelsEarnRate}
          {multiplier} {currency} on hotels
        </strong>{" "}
        with your {productDisplayName} account.
      </span>
    );
  } else if (hotelsEarnRate == homesEarnRate) {
    earnEnhancementTitle = (
      <span className="font-regular">
        Earn{" "}
        <strong>
          {hotelsEarnRate}
          {multiplier} {currency} on stays
        </strong>{" "}
        with {productDisplayName}.
      </span>
    );
  } else if (hotelsEarnRate && homesEarnRate) {
    earnEnhancementTitle = (
      <span className="font-regular">
        Earn{" "}
        <strong>
          {hotelsEarnRate}
          {multiplier} {currency} on hotels
        </strong>{" "}
        and{" "}
        <strong>
          {homesEarnRate}
          {multiplier} {currency} on vacation rentals{" "}
        </strong>
        with {productDisplayName}.
      </span>
    );
  }

  return earnEnhancementTitle;
};

export const CASH_CARD_BENEFITS_TITLE = (productDisplayName: string) => (
  <>
    <strong>{productDisplayName}</strong> Benefits when you book on Capital One
    Travel
  </>
);

export const CASH_CARD_BENEFITS: { [key: string]: LandingBenefit[] } = {
  VARIANT_A: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.HotelDND,
      text: (
        <>
          We <strong>match hotel prices</strong> before and after you book
        </>
      ),
    },
  ],
  VARIANT_B: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.PriceDropProtectionIcon,
      text: (
        <>
          Book your flight when we recommend and get{" "}
          <strong>price drop protection</strong>
        </>
      ),
    },
  ],
  VARIANT_C: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.BadgeRibbon,
      text: (
        <>
          If you find a better price within 24 hours,{" "}
          <strong>we’ll match it</strong>
        </>
      ),
    },
  ],
};

export const MAXIMUM_TRAVELERS_COUNT = 6;
export const MAXIMUM_ROOMS_COUNT = 4;
export const CORP_MAXIMUM_TRAVELERS_COUNT = 18;
export const CORP_MAXIMUM_ROOMS_COUNT = 9;
export const STAYS_MAXIMUM_TRAVELERS_COUNT = 16;
export const STAYS_MAXIMUM_ROOMS_COUNT = 10;
export const PARADISE_TITLE = "Your next journey starts here.";

export const VR_AWARENESS_EARN_ENHANCEMENT = (
  homesEarnRate: string | number,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `${homesEarnRate}${multiplier} ${currency}`;
};

export const VR_AWARENESS_EARN_ENHANCEMENT_TITLE = (
  homesEarnRate: string | number,
  currency: string
) => {
  return `Earn ${VR_AWARENESS_EARN_ENHANCEMENT(
    homesEarnRate,
    currency
  )} on vacation rentals`;
};
