import { Box, ClickAwayListener, Popper } from "@material-ui/core";
import { ButtonWrap, CloseButtonIcon, useDeviceTypes } from "halifax";
import React from "react";
import "./styles.scss";
import {
  getExperimentVariantCustomVariants,
  STAYS_STAY_TYPE_TOOLTIP,
  STAYS_STAY_TYPE_TOOLTIP_VARIANTS,
  STAYS_TOOLTIP_AVAILABLE_IF_HOME_IN_TOP_10_RESULTS,
  STAYS_TOOLTIP_AVAILABLE_IF_HOMES,
  useExperiments,
} from "../../../../context/experiments";

const StayTypeFilterPopover = (props: {
  isOpen: boolean;
  handleClose: () => void;
  anchorRef: React.RefObject<HTMLElement | null>;
}) => {
  const { isOpen, handleClose, anchorRef } = props;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="right"
        style={{ zIndex: 2 }}
      >
        <Box className="stay-type-generic-dropdown-tooltip">
          <Box className="stay-type-generic-dropdown-tooltip-arrow"></Box>
          <Box className="stay-type-generic-dropdown-tooltip-container">
            <Box>
              <Box className="stay-type-generic-dropdown-tooltip-header">
                <Box>
                  <Box className="stay-type-new-badge">New</Box>
                </Box>
                <ButtonWrap
                  className="stay-type-generic-dropdown-tooltip-close-button"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <CloseButtonIcon className="stay-type-generic-dropdown-tooltip-close-icon" />
                </ButtonWrap>
              </Box>
              <Box className="stay-type-generic-dropdown-tooltip-text-box">
                <span className="stay-type-generic-dropdown-tooltip-text">
                  We've added{" "}
                  <span className="stay-type-generic-dropdown-tooltip-text-bolded">
                    professionally managed vacation rentals across the U.S.
                  </span>{" "}
                  View by property type to see vacation rentals, hotels or both.
                </span>
              </Box>
              <Box className="stay-type-generic-dropdown-button-container">
                <ButtonWrap
                  className="stay-type-generic-dropdown-button"
                  onClick={handleClose}
                >
                  Got it!
                </ButtonWrap>
              </Box>
            </Box>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

const MobileStayTypeInfoPopper = (props: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { isOpen, handleClose } = props;

  return (
    <>
      {isOpen && (
        <Box className="mobile-stay-type-filter-pop-up">
          <Box className="mobile-stay-type-tooltip-header">
            <Box>
              <Box className="mobile-stay-type-new-badge">New</Box>
            </Box>
            <ButtonWrap
              className="mobile-stay-type-tooltip-close-button"
              aria-label="Close"
              onClick={handleClose}
            >
              <CloseButtonIcon className="mobile-stay-type-tooltip-close-icon" />
            </ButtonWrap>
          </Box>
          <Box className="mobile-stay-type-tooltip-text-box">
            <span className="mobile-stay-type-tooltip-text">
              We've added{" "}
              <span className="mobile-stay-type-tooltip-text-bolded">
                professionally managed vacation rentals across the U.S.
              </span>{" "}
              View by property type to see vacation rentals, hotels or both.
            </span>
          </Box>
          <ButtonWrap
            className="mobile-stay-type-tooltip-button"
            onClick={handleClose}
          >
            Got it!
          </ButtonWrap>
        </Box>
      )}
    </>
  );
};

export interface ManagedStayTypeFilterPopoverProps {
  availabilityResultsHasHomesAndHotels: boolean;
  availabilityResultsFirstIndexOfHomeResult: number;
  anchorRef?: React.RefObject<HTMLElement | null>;
}

export const ManagedStayTypeFilterPopover = ({
  anchorRef,
  availabilityResultsHasHomesAndHotels,
  availabilityResultsFirstIndexOfHomeResult,
}: ManagedStayTypeFilterPopoverProps) => {
  const localStorageKey = "stayTypeFilterAwarenessPopperSeen";

  const expState = useExperiments();

  const { matchesMobile } = useDeviceTypes();

  const [open, setOpen] = React.useState<boolean>(false);

  const isAlreadySeen = localStorage.getItem(localStorageKey) !== null;

  const variant = getExperimentVariantCustomVariants(
    expState.experiments,
    STAYS_STAY_TYPE_TOOLTIP,
    STAYS_STAY_TYPE_TOOLTIP_VARIANTS
  );

  const canShowBasedOnExperimentVariant =
    variant == STAYS_TOOLTIP_AVAILABLE_IF_HOMES
      ? availabilityResultsHasHomesAndHotels
      : variant == STAYS_TOOLTIP_AVAILABLE_IF_HOME_IN_TOP_10_RESULTS
      ? availabilityResultsFirstIndexOfHomeResult >= 0 &&
        availabilityResultsFirstIndexOfHomeResult < 10
      : false;

  React.useEffect(() => {
    setOpen(canShowBasedOnExperimentVariant && !isAlreadySeen);
  }, [canShowBasedOnExperimentVariant, !isAlreadySeen]);

  const handleClose = () => {
    localStorage.setItem(localStorageKey, "true");
    setOpen(false);
  };

  if (!matchesMobile && (!anchorRef || anchorRef.current === null)) {
    return null;
  }

  return matchesMobile ? (
    <MobileStayTypeInfoPopper isOpen={open} handleClose={handleClose} />
  ) : anchorRef ? (
    <StayTypeFilterPopover
      isOpen={open}
      handleClose={handleClose}
      anchorRef={anchorRef}
    />
  ) : null;
};
